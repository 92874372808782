<template>
  <div id="material-list">
    <v-card>
      <v-card-title> 取引先一覧 </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="2"
        >
          <v-autocomplete
            v-model="cust_id"
            placeholder="取引先"
            :items="customerIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-autocomplete
            v-model="country_id"
            placeholder="国・地域"
            :items="countryMaster"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-autocomplete
            v-model="cust_stat"
            placeholder="状態"
            :items="custStatuses"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="認証日From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="認証日To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" @input="dateEndMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          single-line
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'customer-create', params: { query: this.$route.query } }"
          >
            <span>NEW</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="filteredTable"
        :headers="headers"
        :items="customerList"

        class="elevation-1"
        :search="searchQuery"
      >
        <template v-slot:[`item.cust_abbr`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.cust_abbr }}</a>
            </template>
            <span> {{ item.cust_name_eng }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.mobile`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.mobile }}</a>
            </template>
            <span> {{ item.tel }}</span><br>
            <span> {{ item.fax }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.manager_name`]="{ item }">
          <label>{{ item.manager_sei }} {{ item.manager_mei }}</label>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <div class="cell-content">
            {{ item.email }}
          </div>
        </template>
        <template v-slot:[`item.country`]="{ item }">
          <div>
            <span>{{ getCountry(item) }}</span>
          </div>
        </template>
        <!-- <template v-slot:[`item.cust_stat`]="{ item }">
          <div>
            <span>{{ getCustStat(item) }}</span>
          </div>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <delete-form
              :id="item.cust_id"
              class="py-2 pl-1"
              :icon="icons.mdiDelete"
              @deleted="refreshList"
              @cancel="refreshList"
            ></delete-form>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import {
  mdiPencil, mdiDelete, mdiExportVariant,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

import DeleteForm from './CustomerDelete.vue'

export default {
  components: {
    DeleteForm,
  },
  data: () => ({
    icons: {
      mdiPencil, mdiDelete, mdiExportVariant,
    },
    cust_id: '',
    mobile: '',
    cust_stat: '',
    country_id: '',
    startDate: '',
    endDate: '',
    dateEndMenu: false,
    dateStartMenu: false,
    customerList: [],

    Check: true,
    Uncheck: false,
    searchQuery: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        sortable: true,
        value: 'cust_id',
        width: '8%',
      },
      {
        text: '略称',
        align: 'left',
        sortable: true,
        value: 'cust_abbr',
        width: '12%',
      },
      {
        text: '取引先名',
        sortable: true,
        value: 'cust_name',
        align: 'left',
        width: '20%',
      },
      {
        text: '国・地域',
        sortable: true,
        value: 'country',
        align: 'left',
        width: '10%',
      },
      {
        text: '担当',
        sortable: true,
        value: 'manager_name',
        align: 'center',
        width: '10%',
      },
      {
        text: '電話/FAX',
        sortable: true,
        value: 'mobile',
        align: 'left',
        width: '15%',
      },
      {
        text: '認証日時',
        sortable: true,
        value: 'email_verified_at',
        align: 'left',
        width: '15%',
      },
      {
        text: '状態',
        sortable: true,
        value: 'cust_stat_desc',
        align: 'center',
        width: '10%',
      },

      // {
      //   text: 'メールアドレス',
      //   sortable: true,
      //   value: 'email',
      //   align: 'left',
      //   width: '18%',
      //   fixed: true,
      // },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '8%',
      },
    ],
  }),

  computed: {
    ...mapState('customerStore', ['customers', 'customerIds', 'mobileList', 'custStatuses']),
    ...mapState('countryStore', ['countryMaster', 'countryIds']),
    getCustStat() {
      return function (item) {
        const selStat = this.custStatuses.find(c => c.value === item.cust_stat)
        if (selStat) {
          return selStat.text
        }

        return ''
      }
    },
  },

  watch: {
    country_id(val) {
      this.changeRouteQuery('country_id', val)
      this.refreshList()
    },

    cust_abbr(val) {
      this.changeRouteQuery('cust_abbr', val)
      this.refreshList()
    },

    cust_stat(val) {
      this.changeRouteQuery('cust_stat', val)
      this.refreshList()
    },

    cust_id(val) {
      this.changeRouteQuery('cust_id', val)
      this.refreshList()
    },

    startDate(val) {
      this.changeRouteQuery('startDate', val)
      this.refreshList()
    },
    endDate(val) {
      this.changeRouteQuery('endDate', val)
      this.refreshList()
    },

    // country(val) {
    //   this.changeRouteQuery('country', val)
    //   this.refreshList()
    // },
    // mobile(val) {
    //   this.changeRouteQuery('mobile', val)
    //   this.refreshList()
    // },
    // supplier(val) {
    //   this.changeRouteQuery('supplier', val)
    //   this.refreshList()
    // },
    // customers(value) {
    //   this.customerList = value
    // },
  },

  created() {
    this.changeRouteQuery()
    this.refreshList()
    this.loadQuery()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('customerStore', ['loadCustomers', 'loadCustomerIds', 'loadMobileList', 'loadCustomerStatus']),
    ...mapActions('countryStore', ['loadCountryIds']),
    refreshList() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),

        // this.loadMobileList(),
        this.loadCountryIds(),
        this.loadCustomerStatus(),
        this.loadCustomers(this.$route.query),
      ]).then(() => {
        this.customerList = this.customers
      }).catch(error => {
        console.log('refreshList', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({
        path: this.$route.path, query,
      })
    },
    loadQuery() {
      this.country_id = this.$route.query.country_id ?? ''
      this.cust_id = this.$route.query.cust_id ?? ''
      this.cust_stat = this.$route.query.cust_stat ?? ''
      this.startDate = this.$route.query.start_date ?? ''
      this.endDate = this.$route.query.end_date ?? ''
    },
    editItem(item) {
      console.log('item:', item)
      this.$router.push({
        name: 'customer-edit',
        params: {
          id: item.cust_id, query: this.$route.query,
        },
      })
    },
    getCountry(item) {
      return `${item.country_name}(${item.country_code})`
    },

    // 画面で表示されている情報をcsvにダウンロードする
    downloadCSV() {
      this.loadCustomers(this.$route.query).then(() => {
        const items = this.$refs.filteredTable.$children[0].filteredItems
        const sorted = this.$refs.filteredTable.$children[0].sortItems(items)
        console.log('sorted:', sorted)
        // eslint-disable-next-line no-useless-concat
        let csv = '\ufeff' + 'No,取引先ID,取引先名,国・地域ID(番号),連絡人,携帯番号\n'
        let no = 0
        sorted.forEach(el => {
          if (el.customer_name_eng === null) {
            // eslint-disable-next-line no-param-reassign
            el.customer_name_eng = ''
          }
          if (el.manager_name === undefined) {
            // eslint-disable-next-line no-param-reassign
            el.manager_name = ''
          }

          if (el.country === null || el.country === undefined) {
            // eslint-disable-next-line no-param-reassign
            el.country = ''
          }

          if (el.mobile === null) {
            // eslint-disable-next-line no-param-reassign
            el.mobile = ''
          }

          // // eslint-disable-next-line no-param-reassign
          // el.country = el.country.replace('null', '')
          // // eslint-disable-next-line no-param-reassign
          // el.manager_name = el.manager_name.replace('null', '')

          no += 1
          const line = `${no},'${el.customer_id}','${el.customer_name}','${el.country}','${el.manager_name}','${el.mobile}',\n`
          csv += line
        })
        const blob = new Blob([csv], {
          type: 'text/csv',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
        const newstr = nowstr.replace(/(-|:|T)/g, '')
        console.log(nowstr, newstr)
        link.download = '取引先一覧'.concat('-', newstr, '.csv')
        link.click()
      })
    },

    // ...mapMutations('customerStore', ['filterCustomers']),
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.cell-content {
  flex: 1;
  word-break: break-all;
  min-width: 0;
}
</style>
